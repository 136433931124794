<template>
  <transition name='modal'>
    <div class='modal-mask'>
      <div class='modal-wrapper'>
        <div class='modal-container' :style='modalStyle'>
          <div class='modal-header text-right'>
            <slot name='header'>
              <button @click='modalClose' class='text-xl border rounded-md font-bold hover:shadow-md'><x-icon /></button>
            </slot>
          </div>
          <label class='text-2xl px-8'>{{ title }}</label>
          <component :is='modalComponentName' @done-modal='modalClose' :portfolios='dataRec' :isMain='false'/>
          <div class='modal-footer text-left py-4'>
            <slot name='footer'>
              <button @click='modalClose' class='text-xl border rounded-md font-bold hover:shadow-md'><x-icon /></button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import StrategyReport   from '@/views/portfolio/StrategyReport.vue'
import SectorReport     from '@/views/portfolio/SectorReport.vue'
import CollectionReport from '@/views/portfolio/CollectionReport.vue'
import { XIcon }        from '@vue-hero-icons/outline'

export default {
  name: 'PortfolioModal',
  components: {
    StrategyReport,
    SectorReport,
    CollectionReport,
    XIcon,
  },
  props: [
    'modalName',
    'modalStyle',
    'dataRec',
    'title',
  ],
  computed: {
    modalComponentName () {
      switch (this.modalName) {
        case 'strategy':
          return 'strategy-report'
        case 'sector':
          return 'sector-report'
        case 'collection':
          return 'collection-report'
        default:
          return 'strategy-report'
      }
    },
  },
  methods: {
    modalClose () {
      this.$emit('modal-close')
    },
  },
}
</script>

<style lang='scss' scoped>
.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  float: left;
  margin-left:450px;
}

.modal-container {
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  overflow: auto;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
