<template>
  <div class='pb-8 px-2 lg:pl-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 
          class='text-lg lg:text-3xl font-semibold tracking-wide flex flex-row justify-left items-center gap-x-2'>
          Holdings Report
          <button
            class='border px-2 py-2 lg:py-3 rounded-md border-transparent hover:border-gray-500'
            @click='getPortfolioReport'>
            <refresh-cw-icon class='h-4' />
          </button>
        </h1>
      </div>
    </portal>
    <div class='relative'>
      <div class='my-6 flex flex-col lg:flex-row justify-start items-start lg:items-center gap-x-2 w-full'>
        <div class='flex flex-row justify-start items-center'>
          <portfolio-fund-select
            :selectableFunds='fundList'
            @done-funds-select='doneFundsSelect'
            class='w-64 border rounded' />
          <portfolio-strategy-select
            :selectableStrategys='strategyList'
            @done-strategys-select='doneStrategysSelect'
            class='w-64 border rounded' />
        </div>
        <div class='flex flex-row justify-start items-center'>
          <v-select v-model='sectorLarge'
            :options='sectorLargeList'
            label='섹터'
            class='w-40 text-sm'
            @close='sectorClose'
            :close-on-select='true'>
          </v-select>
          <v-select name='irrSelect'
            v-model='irrType'
            :options='irrList'
            @close='getPortfolioReport'
            class='w-40 text-sm'
            :close-on-select='true' >
          </v-select>
        </div>
        <div class='flex flex-row justify-start items-center'>
          <input type='numeric'
            class=' ml-2 text-right border py-2 px-2 rounded' 
            size='5'
            v-model='minIrr'
            @keyup.enter='getPortfolioReport'>
          <span class=' py-2 px-1'>~</span>
          <input type='numeric'
            class=' text-right border py-2 px-2 rounded' 
            size='5'
            v-model='maxIrr'
            @keyup.enter='getPortfolioReport'>
        </div>
        <el-date-picker
          align='right'
          v-model='date'
          class=' w-full text-right text-sm'
          type='daterange'
          placeholder='투자일자'
          :default-value='date'
          format='yyyy-MM-dd'
          value-format='yyyy-MM-dd'
          @change='changeDate'
          range-separator='~'
          start-placeholder='Start Date'
          end-placeholder='End Date'
          unlink-panels
          :picker-options='pickerOptions'>
        </el-date-picker>
      </div>
    </div>
    <strategy-report 
      :portfolios='portfolioStrategys'
      :isMain='true'
      @click-strategy='clickStrategy'
      class='overflow-x-auto' />
    <div class='flex flex-row overflow-x-auto'>
      <el-radio-group 
        v-model='chartKind' 
        size='large' 
        class='py-8 text-left flex flex-row' 
        style='width:400px' 
        @change='makeBarChartData'>
        <el-radio-button label='투자전략챠트'></el-radio-button>
        <el-radio-button label='섹터챠트'></el-radio-button>
      </el-radio-group>
      <holdings-bar-chart 
        :barChartData='barChartData' 
        @done='changeBarChart' />
    </div>
    <sector-report 
      :portfolios='portfolioSectors' 
      :isMain='true' 
      @click-sector='clickSector'
      class='overflow-x-auto'/>
    <div class='flex flex-row overflow-x-auto'>
      <div class='py-12'>
        <label class='text-2xl w-32 text-left' >투자 추이</label>
        <div class='flex flex-row' style='width:400px;' >
          <label class='text-left py-4 px-2'> View by :</label>
          <v-select class='text-left py-2'
            v-model='month'
            :options='monthList'
            label='월'
            @close='monthSelected'
            :close-on-select='true' >
          </v-select>
        </div>
      </div>
      <period-chart :periodChartData='periodChartData' />
    </div>
    <period-report :portfolios='portfolioPeriods'/>
    <div class='flex flex-row overflow-x-auto'>
      <collection-chart :collectionChartData='collectionChartData' />
      <collection-report :portfolios='portfolioCollections' :isMain='true' @click-collection='clickCollection' />
    </div>
    <portfolio-modal v-if='modalOpen'
      :modalName='detailName'
      :dataRec='detailData'
      :title='detailTitle'
      @modal-close='modalClose'
      class='overflow-x-scroll'
      :modalStyle='"height: 100vh; width: 1600px;"'>
    </portfolio-modal>
  </div>
</template>

<script>
import dayjs               from 'dayjs'
import numbro              from 'numbro'
import { RefreshCwIcon } from 'vue-feather-icons'
import PortfoliosApi           from '@/api/v1/portfolios'
import LedgerCodeApi           from '@/api/v1/ledger_codes'
import StrategyReport          from '@/views/portfolio/StrategyReport.vue'
import SectorReport            from '@/views/portfolio/SectorReport.vue'
import PeriodReport            from '@/views/portfolio/PeriodReport.vue'
import CollectionReport        from '@/views/portfolio/CollectionReport.vue'
import CollectionChart         from '@/views/portfolio/CollectionChart.vue'
import PeriodChart             from '@/views/portfolio/PeriodChart.vue'
import HoldingsBarChart        from '@/views/portfolio/HoldingsBarChart.vue'
import PortfolioModal          from '@/views/portfolio/PortfolioModal.vue'
import PortfolioFundSelect     from '@/views/portfolio/PortfolioFundSelect.vue'
import PortfolioStrategySelect from '@/views/portfolio/PortfolioStrategySelect.vue'


export default {
  name: 'HoldingsReport',
  components: {
    RefreshCwIcon,
    StrategyReport,
    SectorReport,
    CollectionReport,
    PeriodReport,
    CollectionChart,
    PeriodChart,
    HoldingsBarChart,
    PortfolioModal,
    PortfolioFundSelect,
    PortfolioStrategySelect,
  },
  data () {
    return {
      modalOpen: false,
      irrType: 'IRR-미계산',
      irrList: ['IRR-미계산', '전략별-IRR', '투자별-IRR'],
      minIrr: -999,
      maxIrr: 999,
      detailTitle: '',
      detailData: [],
      detailName: '',
      portfolioStrategys: [],
      portfolioSectors: [],
      portfolioCollections: [],
      portfolioPeriods: [],
      collectionChartData: [],
      periodChartData: { isChange: 'N', date: [], value: [], count: [], collection: [] },
      barChartData: { isChange: 'N', name: [], value: [], profit: [] },
      month: '반기',
      monthList: ['1개월', '분기', '반기', '1년'],
      funds: [],
      uploadStrategys: [],
      sectorLarge: 'All Sectors',
      strategyList: [],
      sectorLargeList: [],
      fundList: [],
      chartKind: '투자전략챠트',
      date: ['2014-01-01', dayjs().format('YYYY-MM-DD')],
      pickerOptions: {
        shortcuts: [{
          text: '금년',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0, 1);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '6개월',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '1년',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '3년',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().setFullYear(new Date().getFullYear() - 3));
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  methods: {
    doneFundsSelect (selectedList) {
      this.funds = selectedList
      this.getPortfolioReport ()
    },
    doneStrategysSelect (selectedList) {
      this.uploadStrategys = selectedList
      this.getPortfolioReport ()
    },
    modalClose () {
      this.modalOpen = false
    },
    changeBarChart () {
      this.barChartData.isChange = 'N'
    },
    getPortfolioReport () {
      if (this.funds.length > 0) {
        this.getPortfolioStrategy ()
        this.getPortfolioCollection ()
        this.getPortfolioSector ()
        this.getPortfolioPeriod ()
      }
    },
    setDefaultFilter () {
      let defaultFilter = {
        start_date: dayjs(this.date[0]).format('YYYYMMDD'),
        end_date: dayjs(this.date[1]).format('YYYYMMDD'),
        short_names: this.funds,
        irrType: this.irrType,
        minIrr: this.minIrr,
        maxIrr: this.maxIrr,
      }

      return defaultFilter
    },
    getPortfolioStrategy () {
      let filters = this.setDefaultFilter()
      filters.collection_status = '전체'
      filters.is_group = 'YES'
      filters.sector_large = this.sectorLarge

      PortfoliosApi.getPortfolioStrategy ( filters ).then(resp => {
        this.portfolioStrategys = resp
        this.makeBarChartData ()
      })
    },
    getPortfolioCollection () {
      let filters = this.setDefaultFilter()
      filters.sector_large = this.sectorLarge
      filters.collection_status = '회수완료'
      filters.is_group = 'YES'

      PortfoliosApi.getPortfolioStrategy ( filters ).then(resp => {
        this.portfolioCollections = resp
        this.collectionChartData = []
        this.portfolioCollections.forEach( portfolio => {
          this.collectionChartData.push ( {value: portfolio.profit, name: portfolio.strategy} )
        })
      })
    },
    getPortfolioSector () {
      let filters = this.setDefaultFilter()
      filters.strategys = this.uploadStrategys
      filters.is_group = 'YES'

      PortfoliosApi.getPortfolioSector ( filters ).then(resp => {
        this.portfolioSectors = resp
        this.makeBarChartData ()
      })
    },
    getPortfolioPeriod () {
      let filters = this.setDefaultFilter()
      filters.strategys = this.uploadStrategys
      filters.sector_large = this.sectorLarge
      filters.period_month = this.month

      PortfoliosApi.getPortfolioPeriod ( filters ).then(resp => {
        this.portfolioPeriods = resp
        this.periodChartData.date = []
        this.periodChartData.value = []
        this.periodChartData.count = []
        this.periodChartData.collection = []
        this.portfolioPeriods.forEach( portfolio => {
          this.periodChartData.date.push ( portfolio.investment_date )
          this.periodChartData.value.push ( Math.round(portfolio.investment_value / 100000000) )
          this.periodChartData.count.push ( portfolio.investment_count )
          this.periodChartData.collection.push ( Math.round(portfolio.collection_value / 100000000) )
        })
      })
    },
    getStrategys () {
      LedgerCodeApi.getLedgerCodes({search_type: 'strategy'}).then(resp => {
        resp.forEach((ledger_code) => {
          if (ledger_code.code_name !== '제외') {
            this.strategyList.push(ledger_code.code_name)
          }
        })
      })
    },
    getSectors () {
      this.sectorLargeList.push('All Sectors')
      LedgerCodeApi.getLedgerCodes({search_type: 'sector_large'}).then(resp => {
        resp.forEach((sector_code) => { this.sectorLargeList.push(sector_code.code_name) })
      })
    },
    getFundList () {
      LedgerCodeApi.getFundList().then(resp => {
        resp.forEach(fund => { this.fundList.push(fund.short_name) })
      })
    },
    toNumber (number) {
      if (number) {
        return numbro(parseInt(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    makeBarChartData () {
      this.barChartData.isChange = 'N'
      this.barChartData.name = []
      this.barChartData.value = []
      this.barChartData.profit = []
      if (this.chartKind === '투자전략챠트') {
        this.portfolioStrategys.forEach( portfolio => {
          this.barChartData.name.push ( portfolio.strategy )
          this.barChartData.value.push ( Math.round(portfolio.investment_value / 100000000) )
          this.barChartData.profit.push ( Math.round(portfolio.profit / 100000000) )
        })
      } else {
        this.portfolioSectors.forEach( portfolio => {
          this.barChartData.name.push ( portfolio.sector_small )
          this.barChartData.value.push ( Math.round(portfolio.investment_value /100000000) )
          this.barChartData.profit.push ( Math.round(portfolio.profit /100000000) )
        })
      }
      this.barChartData.isChange = 'Y'
    },
    monthSelected () {
      this.getPortfolioPeriod ()
    },
    changeDate () {
      this.getPortfolioStrategy ()
      this.getPortfolioSector ()
      this.getPortfolioCollection ()
      this.getPortfolioPeriod ()
    },
    sectorClose () {
      this.getPortfolioStrategy ()
      this.getPortfolioCollection ()
      this.getPortfolioPeriod ()
    },
    strategyClose () {
      this.getPortfolioSector ()
      this.getPortfolioCollection ()
      this.getPortfolioPeriod ()
    },
    clickStrategy (portfolio) {
      let filters = this.setDefaultFilter()
      filters.sector_large = this.sectorLarge
      filters.collection_status = '전체'
      filters.is_group = 'NO'
      filters.strategy = portfolio.strategy

      PortfoliosApi.getPortfolioStrategy ( filters ).then(resp => {
        this.detailData = resp
        this.detailTitle = portfolio.strategy
        this.detailName = 'strategy'
        this.modalOpen = true
      })
    },
    clickSector (portfolio) {
      let filters = this.setDefaultFilter()
      filters.strategys = this.uploadStrategys
      filters.is_group = 'NO'
      filters.sector_large = portfolio.sector_large

      PortfoliosApi.getPortfolioSector ( filters ).then(resp => {
        this.detailData = resp
        this.detailTitle = portfolio.sector_large
        this.detailName = 'sector'
        this.modalOpen = true
      })
    },
    clickCollection (portfolio) {
      let filters = this.setDefaultFilter()
      filters.sector_large = this.sectorLarge
      filters.collection_status = '회수완료'
      filters.is_group = 'NO'
      filters.strategy = portfolio.strategy

      PortfoliosApi.getPortfolioStrategy ( filters ).then(resp => {
        this.detailData = resp
        this.detailTitle = portfolio.strategy
        this.detailName = 'collection'
        this.modalOpen = true
      })
    },
  },
  mounted () {
    this.getStrategys ()
    this.getSectors ()
    this.getFundList ()
  },
}
</script>

<style lang='scss' scoped>
  th {
    @apply py-3 px-2 uppercase tracking-wide;
    background-color: #FFF;
    white-space: nowrap;
    z-index: 2;
  }
  td {
    @apply py-3 pl-2 pr-6 border-b;
    white-space: nowrap;
  }
</style>
